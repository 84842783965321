<template>
  <div class="view beta-info">
    <navbar/>
    <main class="flex-container">
      <article class="content">
        <header>
          <h1>The Future of conlang.tools </h1>
          <p class="lead">A note to users about the future of conlang.tools.</p>
          <p class="warning"><strong>TLDR:</strong> I'm working on conlang.tools again! Save your work if you don't want to lose it in upcoming updates. Join the <a href="https://discord.gg/yc5E5qbk6S" target="_blank">conlang.tools Discord server</a>!</p>
        </header>
        <section id="about">
          <h2>State of the Project</h2>
          <p>I created Conlang Workbench two years ago as my final year project in university. I published the project as an open beta test, which enabled me to gather a wealth of high quality feedback from the conlanging community, which I was able to include in my dissertation report. At that time the tool was well received, but there were a few known flaws, with some highly desired features missing entirely.</p>
          <p>I had planned to continue development of Conlang Workbench after graduating from university, but sadly those plans never came to fruition, with further studies, work, and other projects getting in the way. Conlang Workbench has been sat unmaintained and unimproved for nearly two years now, all the while with a tiny base of users putting the tools to good use.</p>
        </section>
        <section id="what-next">
          <h2>What Next?</h2>
          <p>I am currently rewriting the conlang definition language parser and the sound change applier - the two main components of Conlang Workbench - from scratch. I am applying a lot of lessons learnt the first time round, as well as trying out some new technologies.</p>
          <p>The new tools will be hosted here, and will replace the current Conlang Workbench. In addition to the web-based editor, the sound-change applier will be available as a stand-alone command-line tool.</p>
          <p>Significant changes are coming to the conlang definition language (henceforth known as <strong>chronlang</strong>), meaning that existing projects will no longer be compatible with the new tools. On top of this, while it is safe and secure, the user profile/login system of conlang.tools was something of an afterthought, meant primarly to facilitate the beta test. As a result, it is not as smooth or as feature-rich as I would have liked.</p>
          <p>All of the above is to say that <strong class="warning">user accounts and projects will be deleted in preparation for the new generation of conlang.tools</strong>. If you have any valuable projects saved in your account, please download your code and save it on your own device before Conlang Workbench goes away. </p>
          <p>Conlang Workbench will be unavailable as of the <span class="warning">1st of June, 2023</span> - exactly two years after I last worked on the original site. On this date, <a href="https://conlang.tools/">conlang.tools</a> will lead to a landing page for the new toolset.</p>
        </section>
        <section id="community">
          <h2>Community</h2>
          <p>With the new generation of conlang.tools, I would like to start building more of a community around the site. I would love for users to be able to share their projects, help each other get to grips with the tools, and share feedback to help me improve conlang.tools. To this end, I have created a <a href="https://discord.gg/yc5E5qbk6S" target="_blank">conlang.tools Discord server</a></p>
          <p>On top of this, conlang.tools will be fully open-source moving forward. This means that anyone in the community will be able to contribute to maintaining and improving the conlang.tools! </p>
        </section>
        <section id="thanks">
          <h2>Thanks</h2>
          <p>That's it for the update! Thanks for reading, and thanks for using conlang.tools! Make sure to join the Discord server for all future updates :)</p>
          <p>Bye for now,<br>Fergus</p>
        </section>
      </article>
    </main>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'NotFound',
  components: { Navbar },
}
</script>

<style lang="scss" scoped>
.beta-info {
  .content {
    text-align: left;

    h1, .lead {
      text-align: center;
    }

    .warning {
      color: var(--c-01);
    }

    dl {

      padding-left: 3.5rem;

      dt {
        font-weight: 800;
        position: relative;

        &::before {
          content: '•';
          display: inline-block;
          position: absolute;
          left: -1.5rem;
        }
      }

      dd + dt {
        margin-top: 1rem;
      }
    }
  }
}
</style>
